@import url('https://fonts.googleapis.com/css2?family=Kanit&family=Lobster&family=Poppins&family=Roboto+Mono&family=Sono&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background-color: #e5e7eb;
    height: 100vh;
}

.box-shadow1 {
    box-shadow: rgba(50, 50, 93, 0.25) 0 50px 100px -20px, rgba(0, 0, 0, 0.3) 0 30px 60px -30px, rgba(10, 37, 64, 0.35) 0 -2px 6px 0 inset;
}

.box-shadow2 {
    box-shadow: rgb(38, 57, 77) 0 20px 30px -10px;
}

.box-shadow3 {
    box-shadow: rgba(0, 0, 0, 0.17) 0 -23px 25px 0 inset, rgba(0, 0, 0, 0.15) 0 -36px 30px 0 inset, rgba(0, 0, 0, 0.1) 0 -79px 40px 0 inset, rgba(0, 0, 0, 0.06) 0 2px 1px, rgba(0, 0, 0, 0.09) 0 4px 2px, rgba(0, 0, 0, 0.09) 0 8px 4px, rgba(0, 0, 0, 0.09) 0 16px 8px, rgba(0, 0, 0, 0.09) 0 32px 16px;
}

.box-shadow4 {
    box-shadow: rgba(0, 0, 0, 0.4) 0 2px 4px, rgba(0, 0, 0, 0.3) 0 7px 13px -3px, rgba(0, 0, 0, 0.2) 0 -3px 0 inset;
}

.thumb::-webkit-slider-thumb {
    box-shadow: 1px 1px 2px #A6A6A6;
    height: 22px;
    width: 22px;
    border-radius: 100%;
    background: #48A9A6;
    opacity: 90%;
    cursor: pointer;
    -webkit-appearance: none;
}

.image-clignote  {
    animation-duration: 1.5s;
    animation-name: clignoter;
    animation-iteration-count: infinite;
    transition: none;
}
@keyframes clignoter {
    0%   { opacity:1; }
    40%   {opacity:0; }
    100% { opacity:1; }
}

@media screen and (min-width: 768px){
    #show-playlist {
        display: none;
    }
}


@media screen and (max-width: 768px) {
    .box-shadow1, .box-shadow2, .box-shadow3, .box-shadow4 {
        box-shadow: none;
    }

    #admin-panel, #volume {
        display: none;
    }

    .sizePlaylist {
        min-height: 100vh;
    }
    /* Texte défilant */
    .messagedefilant {
        display: block;
        padding: 0;
        /*overflow: hidden;*/
        position: relative;
        width: 100%;
        max-width: 640px;
    }

    .messagedefilant div {
        position: absolute;
        min-width: 100%; /* au minimum la largeur du conteneur */
    }

    .messagedefilant div span,
    .messagedefilant div:after {
        position: relative;
        display: inline-block;
        white-space: nowrap;
        top:0;
    }

    .messagedefilant div span {
        animation: defilement 10s infinite linear;
    }

    .messagedefilant div:after {
        position: absolute;
        top:0; left:0;
        content:attr(data-text);
        animation: defilement2 10s infinite linear;
    }

    @keyframes defilement {
        0% { margin-left: 0; }
        100% { margin-left: -100%; }
    }

    @keyframes defilement2 {
        0% { margin-left: 100%; }
        100% { margin-left: 0; }
    }

}